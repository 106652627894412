var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config-index-component table-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("数据字典")])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "a-layout",
          [
            _c(
              "a-layout-sider",
              { staticClass: "page-menu-sider", attrs: { theme: "light" } },
              [
                _c(
                  "a-card",
                  { attrs: { title: "数据字典", bordered: false } },
                  [
                    _c(
                      "a-menu",
                      {
                        attrs: {
                          "selected-keys": [_vm.SelectedKeys],
                          theme: "light"
                        }
                      },
                      [
                        _vm._l(_vm.allPropertyTypes, function(item) {
                          return [
                            _c(
                              "a-menu-item",
                              { key: item.propertyType },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/dashboard/config/property/" +
                                        item.propertyType
                                    }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ],
                              1
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("a-layout-content", [_c("router-view")], 1)
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }