








































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { PropertyEntityType } from '@/model/enum';
import CommonService from '@/service/common';

@Component
export default class ConfigIndexComponent extends BaseComponent {
    PropertyEntityType = PropertyEntityType;
    allPropertyTypes: Array<any> = null;

    created() {
        CommonService.getAllPropertyTypes().then(res => {
            this.allPropertyTypes = res;
            this.$router.push(`/dashboard/config/property/${_.get(this.allPropertyTypes, '[0].propertyType')}`);
        });
    }

    get SelectedKeys(): string {
        if (this.$route.name === 'property') {
            return this.$route.params.type;
        }
        return this.$route.name || '';
    }
}

